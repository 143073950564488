<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.scan_and_print')">
        <textarea class="console"
                  v-model="console"
                  readonly
                  ref="console"
        ></textarea>
        <input type="text" @keyup.enter="manualEnter" v-model="manualCode">
        <button @click="manualEnter">Manual enter</button>
    </zw-sidebar>
</template>

<script>
export default {
    name: 'ScanAndPrintModal',
    data() {
        return {
            payload: {},
            manualCode: '',
            code: '',
            reading: false,
            console: '',
            successCode: null,
        }
    },
    created() {
        window.addEventListener('keypress', this.onKeyPress);
    },
    beforeDestroy() {
        window.removeEventListener('keypress', this.onKeyPress);
    },
    methods: {
        shown() {
        },
        sendToPrinter(image) {
            this.printToConsole('Sending to printer...')
            let newData = image.replace(/^data:image\/png/, "data:application/octet-stream");
            //Splitting base64 string
            let arr_count = newData.match(/.{1,100000}/g).length;
            let arr = newData.match(/.{1,100000}/g);
            if (this.sendWebSocket(arr_count, true)) {
                arr.forEach((value, index) => {
                    this.sendWebSocket(value)
                })
            }
        },
        sendWebSocket(value, first = false) {
            let ws = new WebSocket("ws://127.0.0.1:1989/");
            ws.onopen = () => {
                if (first) {
                    this.printToConsole('Printer OK')
                    this.sendPrinted()
                }
                ws.send(value);
            };
            ws.onerror = () => {
                if (first) {
                    this.printToConsole('Printer Error')
                }
            }

            return true;
        },
        getLabel(code) {
            this.printToConsole('Getting label...')
            this.$store.dispatch('Barcode/getLabel', code)
                .then(response => {
                    if (response.data.success == false) {
                        this.printToConsole('Response: ' + response.data.message)
                    } else if (response.data.success) {
                        this.printToConsole('Label received successfully')
                        this.successCode = code
                        this.sendToPrinter(response.data.image)
                    }
                })
        },
        sendPrinted() {
            this.$store.dispatch('Barcode/labelPrinted', this.successCode)
        },
        manualEnter() {
            this.printToConsole('Manually entered code: ' + this.manualCode)
            this.getLabel(this.manualCode)
            this.manualCode = ''
        },
        onKeyPress(e) {
            if (e.key === 'Enter') {
                if (this.code.length > 6) {
                    this.printToConsole('Scanned code: ' + this.code)
                    this.getLabel(this.code)
                    this.code = '';
                }
            } else {
                this.code += e.key;
            }

            if (!this.reading) {
                this.reading = true;
                setTimeout(() => {
                    this.code = '';
                    this.reading = false;
                }, 200);
            }
        },
        printToConsole(text) {
            let current = new Date();
            this.console += current.toLocaleTimeString() + ': ' + text + '\n'

            this.$refs.console.scrollTop = this.$refs.console.scrollHeight
        }
    },
}
</script>

<style>
.console {
    width: 100%;
    height: 300px;
    background: black;
    color: white;
}
</style>